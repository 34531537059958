import React from "react";
/**
 * data Columns for grid and mobile view
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} The Data Columns Array
 */
export const ResultDataColumns = () => {
  const dataColumns = [
    {
      field: "row_no",
      title: "Row No",
      editable: false,
      show: false,
      width: "250px",
      width: "250px",
      minResizableWidth: 150,
      filterable: true,
    },
    {
      field: "tenant_id",
      title: "Tenant",
      editable: false,
      show: false,
      width: "200px",
      minResizableWidth: 150,
      filterable: false,
    },
    {
      field: "uc_ossi_app_test_id",
      title: "Test Id",
      editable: false,
      show: true,
      width: "250px",
      minResizableWidth: 50,
      filterable: false,
    },
    {
      field: "uc_ossi_test_case_id",
      title: "Test Case Id",
      editable: false,
      show: true,
      width: "250px",
      minResizableWidth: 50,
      filterable: false,
    },
    {
      field: "uc_env_id",
      title: "Env Id",
      editable: false,
      show: true,
      width: "250px",
      minResizableWidth: 50,
      filterable: false,
    },
    {
      field: "uc_ossi_arg_id",
      title: "Argument ID",
      editable: false,
      show: true,
      width: "250px",
      minResizableWidth: 50,
      filterable: false,
    },
    {
      field: "uc_ossi_data_val",
      title: "Data Value",
      editable: false,
      show: true,
      width: "250px",
      minResizableWidth: 50,
      filterable: false,
    },
  ];

  return dataColumns;
};
export default ResultDataColumns;
