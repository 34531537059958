import React from "react";
/**
 * data Columns for grid and mobile view
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} The Data Columns Array
 */
export const ScreenshotsDataColumns = () => {
  const dataColumns = [
    {
      field: "row_no",
      title: "Row No",
      editable: false,
      show: false,
      width: "250px",
      width: "250px",
      minResizableWidth: 150,
      filterable: true,
    },
    {
      field: "tenant_id",
      title: "Tenant",
      editable: false,
      show: false,
      width: "200px",
      minResizableWidth: 150,
      filterable: false,
    },
    {
      field: "uc_ossi_screenshot_index",
      title: "Index",
      editable: false,
      show: true,
      width: "200px",
      minResizableWidth: 50,
      filterable: false,
    },
    {
      field: "uc_ossi_screenshot",
      title: "Screenshots",
      editable: false,
      show: true,
      width: "500px",
      minResizableWidth: 50,
      filterable: false,
    },
    {
      field: "uc_ossi_descr",
      title: "Description",
      editable: false,
      show: true,
      width: "250px",
      minResizableWidth: 50,
      filterable: false,
    },
    {
      field: "uc_ossi_frm_id",
      title: "Form",
      editable: false,
      show: true,
      width: "150px",
      minResizableWidth: 50,
      filterable: false,
    },
    {
      field: "uc_ossi_fld_id",
      title: "Field",
      editable: false,
      show: true,
      width: "150px",
      minResizableWidth: 50,
      filterable: false,
    },
    {
      field: "fld_value",
      title: "Value",
      editable: false,
      show: true,
      width: "150px",
      minResizableWidth: 50,
      filterable: false,
    },
    {
      field: "uc_ossi_img",
      title: "Screenshot",
      editable: false,
      show: true,
      width: "150px",
      minResizableWidth: 50,
      filterable: false,
    },
  ];

  return dataColumns;
};
export default ScreenshotsDataColumns;
